import Mustache from 'mustache';
import d from './DOM';

export default abstract class template {
	private static cache: { [templateName: string]: string } = {};
	
	public static render(htmlString: string, dataObject?: any) {
		if (htmlString == null) {
			return '';
		}
		
		return Mustache.render(htmlString, dataObject ?? {});
	}
	
	public static renderDOM(templateName: string, dataObject: any, domElementTagName?: string) {
		const htmlElement = document.createElement(domElementTagName ?? 'div');
		
		if (template.cache[templateName] == null) {
			template.cache[templateName] = d.ht(templateName);
		}
		
		if (template.cache[templateName] != null) {
			htmlElement.innerHTML = Mustache.render(template.cache[templateName], dataObject);
		}
		
		return htmlElement.firstElementChild;
	}
	
	public static html(templateSelector: string, domObj: HTMLElement, arrayOfObjects: any[]) {
		const htmlTemplate = d.ht(templateSelector);
		const html = [];
		
		for (const item of arrayOfObjects) {
			html.push(template.render(htmlTemplate, item));
		}
		
		domObj.innerHTML = html.join(`\n`);
	}
}

window['template'] = template;
