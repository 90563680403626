import '../public/plugins/fontawesome/css/fontawesome.min.css';
import '../public/plugins/fontawesome/css/solid.min.css';
import '../public/plugins/google-fonts/fonts.css';
import '../public/styles/helper.css';
import '../public/styles/login.css';
import '../public/styles/reachBranding.css';
import { browserSupportsWebAuthn, browserSupportsWebAuthnAutofill, startAuthentication } from '@simplewebauthn/browser';
import { AuthenticationResponseJSON } from '@simplewebauthn/typescript-types';
import Cookies from 'js-cookie';
import d from './DOM';
import type { LoginPayload, LoginSSOPayload } from './interfaces';
import template from './template';

export default abstract class login {
	private static payload: LoginPayload;
	
	public static async init(loginPayload: string) {
		login.payload = JSON.parse(loginPayload);
		login.initRevealErrors();
		login.initShowPassword();
		login.initRememberMe();
		login.initResetPassword();
		login.initSAML();
		login.initGoogleOAuth();
		login.initHideManualFields();
		login.initAssistedLogin();
		
		d.qs<input>('#loginForm').onsubmit = async (e: Event) => {
			e.preventDefault();
			await login.authenticate();
		};
		
		d.e('click', login.loginButtonClickHandler, d.qsa<button>('.loginButton'));
		
		if (browserSupportsWebAuthn()) {
			d.get<button>('loginWithPasskey').classList.remove('hidden');
			
			// if the useragent allows conditional here, we can skip right to auth
			if (await browserSupportsWebAuthnAutofill()) {
				await login.passkeyAuth(true);
			}
		}
	}
	
	private static loginButtonClickHandler(e: PointerEvent) {
		const loginButton = <button>login.getParentHierarchy(e).find(l => l.classList.contains('loginButton'));
		
		if (loginButton != null) {
			const action = loginButton.dataset.action;
			
			if (action == 'passkey') { void login.passkeyAuth(); }
			if (action == 'alt') { void login.assistedLoginAuth(); }
		}
	}
	
	private static initRevealErrors() {
		const ssoError = Cookies.get('__rse');
		const ssoSource = Cookies.get('__rss') as LoginSSOPayload['source'];
		
		if (ssoError) {
			Cookies.remove('__rse');
			Cookies.remove('__rss');
			let errorText = 'Unknown error';
			
			if (!ssoSource || ssoSource == 'saml' || ssoSource == 'bb') {
				if (ssoError == '1') {
					errorText = 'No user account found with that username';
				} else if (ssoError == '2') {
					errorText = 'Unable to validate the request';
				}
			} else if (ssoSource == 'msp' || ssoSource == 'goauth') {
				if (ssoError == '1') {
					errorText = 'No user account found with that email';
				} else if (ssoError == '2') {
					errorText = 'Unable to validate the request';
				}
			}
			
			login.displayError(`${(ssoSource || 'SSO').toUpperCase()}0${ssoError}`, errorText);
		}
	}
	
	private static initShowPassword() {
		const sp = d.get<anchor>('showPassword');
		
		if (sp != null) {
			sp.addEventListener('click', () => {
				const pwd = d.get<input>('password');
				
				if (pwd != null) {
					if (pwd.type == 'password') { pwd.type = 'text'; sp.innerHTML = 'Hide Password'; }
					else if (pwd.type == 'text') { pwd.type = 'password'; sp.innerHTML = 'Show Password'; }
				}
			});
		}
	}
	
	private static initRememberMe() {
		const rmu = Cookies.get('__r5rmu');
		const rmp = Cookies.get('__r5rmp');
		let rmChecked = false;
		
		if (rmu != null && rmp != null) {
			const username = d.get<input>('username');
			const password = d.get<input>('password');
			
			if (username != null && password != null) {
				username.value = rmu;
				password.value = login.unscramble(rmu, rmp);
				rmChecked = true;
			}
		}
		
		const rm = d.get<input>('rememberMe');
		
		if (rm != null) {
			rm.addEventListener('click', (e: PointerEvent) => {
				const cbx = e.target as HTMLInputElement;
				login.rememberMe(cbx.checked);
			});
			
			rm.checked = rmChecked;
		}
	}
	
	private static rememberMe(set: boolean) {
		if (set) {
			const username = d.get<input>('username');
			const password = d.get<input>('password');
			
			if (username != null && password != null) {
				const enc = login.scramble(username.value, password.value);
				Cookies.set('__r5rmu', username.value, { secure: true });
				Cookies.set('__r5rmp', enc, { secure: true });
			}
		} else {
			Cookies.remove('__r5rmu');
			Cookies.remove('__r5rmp');
		}
	}
	
	private static scramble(salt: string, text: string) {
		const textToChars = (str) => str.split('').map((c) => c.charCodeAt(0));
		const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
		const applySaltToChar = (code) => textToChars(salt).reduce((a: number, b: number) => a ^ b, code);
		return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
	}
	
	private static unscramble(salt: string, encoded: string) {
		const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
		const applySaltToChar = (code) => textToChars(salt).reduce((a: number, b: number) => a ^ b, code);
		return encoded.match(/.{1,2}/g).map((hex) => parseInt(hex, 16)).map(applySaltToChar).map((charCode) => String.fromCharCode(charCode)).join('');
	}
	
	private static initHideManualFields() {
		if (login.payload.hideManualLogin) {
			const revealLink = d.get<anchor>('loginRevealManualLink');
			
			revealLink.classList.remove('hidden');
			revealLink.addEventListener('click', () => login.displayManualFields(true));
			
			login.displayManualFields(false);
		}
	}
	
	private static displayManualFields(show: boolean) {
		if (show) {
			d.get<anchor>('loginRevealManualLink').classList.add('hidden');
		}
		
		for (const manualField of d.qsa<HTMLDivElement>('.loginManualField')) {
			if (show) {
				manualField.classList.remove('hidden');
			} else {
				manualField.classList.add('hidden');
			}
		}
	}
	
	private static initResetPassword() {
		const rp = d.get<anchor>('resetPassword');
		
		if (rp != null) {
			rp.addEventListener('click', () => {
				if (login.payload.fpLink == '/ForgottenPassword') {
					document.location.href = '/ForgottenPassword';
				} else if (login.payload.fpLink.indexOf('message::') > -1) {
					alert(decodeURIComponent(login.payload.fpLink.substring(9)));
				} else {
					document.location.href = login.payload.fpLink;
				}
			});
		}
	}
	
	private static initAssistedLogin() {
		const rp = d.get<anchor>('assistedLogin');
		if (login.payload.assistedLoginEnabled) {
			rp.classList.remove('hidden');
		}
		
		if (rp != null) {
			rp.addEventListener('click', () => {
				d.qs<div>('.assistedLoginPane').classList.remove('hidden');
			});
		}
	}
	
	private static async assistedLoginAuth() {
		const alt = d.get<input>('txtALT').value;
		if (!alt || !alt.trim()) {
			return;
		}
		
		login.setFormEnabled(false);
		login.showMS();
		
		try {
			const response = await fetch('VerifyALToken', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ alt, src: 'web' })
			});
			
			if (!response.ok) { // non-200 HTTP error
				login.displayError(`AUTH${response.status}`, 'An unexpected error occurred.');
			} else {
				const result = await response.json();
				
				if (result.error || result.code) { // login error (e.g. AUTH1)
					Cookies.remove('authToken', { domain: window.location.host });
					login.displayError(result.code, result.error);
				} else {
					Cookies.set('authToken', result.tokenID, {
						expires: 5500,
						domain: window.location.host,
						secure: true
					});
					
					window.location.reload();
				}
			}
		} catch (error) {
			if (error instanceof SyntaxError) { // JSON parse error
				login.displayError('AUTH0415', 'Unexpected authentication response.');
			} else { // fetch error (e.g. invalid URL, network error)
				login.displayError('AUTH0400', 'A network error occurred.');
			}
		} finally {
			login.hideMS();
			login.setFormEnabled(true);
			d.get<input>('txtALT').value = '';
			d.qs<div>('.assistedLoginPane').classList.add('hidden');
		}
	}
	
	private static initSAML() {
		const lap = d.qs('.extraActionPane');
		if (!login.payload.samlEnabled) {
			return;
		}
		
		for (const samlIDP of login.payload.samlIDPs) {
			if (samlIDP.buttonLabel) {
				const samlBtn = document.createElement('button');
				samlBtn.type = 'button';
				samlBtn.className = 'extraAuthButton';
				samlBtn.innerText = samlIDP.buttonLabel;
				samlBtn.addEventListener('click', () => login.loginSAML(samlIDP));
				lap.appendChild(samlBtn);
			}
		}
	}
	
	private static loginSAML(idp: LoginPayload['samlIDPs'][0]) {
		if (idp.loginURL) {
			document.location.href = idp.loginURL;
		}
	}
	
	private static initGoogleOAuth()  {
		const lap = d.qs('.extraActionPane');
		if (!login.payload.goauthEnabled) {
			return;
		}
		
		const goauthBtn = document.createElement('button');
		goauthBtn.type = 'button';
		goauthBtn.id = 'oauthGoogleBtn';
		goauthBtn.title = 'Sign in with Google';
		goauthBtn.innerHTML = `<img src="images/btn_google_signin_light_normal_web.png" alt="Sign in with Google">`;
		goauthBtn.addEventListener('click', login.googleOAuth);
		lap.appendChild(goauthBtn);
	}
	
	private static googleOAuth() {
		const clientID = '1074024647220-l5d9bkk77ahdeasmcrkkk7q2l5n9aaf7.apps.googleusercontent.com';
		const redirect_uri = 'https://google.reach.cloud/OAuthReceiver/';
		const scope = 'email';
		
		document.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&state=${document.location.host}&redirect_uri=${redirect_uri}&response_type=token&client_id=${clientID}`;
	}
	
	private static setFormEnabled(enabled: boolean) {
		for (const el of d.qsa<HTMLInputElement|HTMLButtonElement>('input, button, a', d.qs('.loginPanel'))) {
			if (enabled) {
				el.classList.remove('disabled');
				el.removeAttribute('disabled');
			} else {
				el.classList.add('disabled');
				el.setAttribute('disabled', 'disabled');
			}
		}
	}
	
	private static async authenticate() {
		login.hideError();
		
		const username = d.get<input>('username');
		const password = d.get<input>('password');
		
		if (username.value == '') {
			alert('Please enter your username');
			username.focus();
		} else if (password.value == '') {
			alert('Please enter your password');
			password.focus();
		} else {
			login.setFormEnabled(false);
			login.showMS();
			
			try {
				const response = await fetch('Authenticate', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						username: username.value,
						password: password.value,
						src: 'web'
					})
				});
				
				if (!response.ok) { // non-200 HTTP error
					login.displayError(`AUTH${response.status}`, 'An unexpected error occurred.');
				} else {
					const result = await response.json();
					
					if (result.error || result.code) { // login error (e.g. AUTH1)
						Cookies.remove('authToken', { domain: window.location.host });
						login.displayError(result.code, result.error);
					} else {
						Cookies.set('authToken', result.tokenID, {
							expires: 5500,
							domain: window.location.host,
							secure: true
						});
						
						window.location.reload();
					}
				}
			} catch (error) {
				if (error instanceof SyntaxError) { // JSON parse error
					login.displayError('AUTH0415', 'Unexpected authentication response.');
				} else { // fetch error (e.g. invalid URL, network error)
					login.displayError('AUTH0400', 'A network error occurred.');
				}
			} finally {
				login.hideMS();
				login.setFormEnabled(true);
			}
		}
	}
	
	private static hideError() {
		const errorPanel = d.get<div>('errorPanel');
		
		if (errorPanel != null) {
			errorPanel.classList.add('hidden');
		}
	}
	
	private static displayError(code: string, message: string) {
		const errorPanel = d.get<div>('errorPanel');
		const errorCode = d.get<span>('errorCode');
		const errorMessage = d.get<span>('errorMessage');
		
		if (errorPanel != null && errorCode != null && errorMessage != null) {
			errorPanel.classList.remove('hidden');
			errorCode.innerText = code;
			errorMessage.innerText = message;
		}
	}
	
	private static showMS() {
		const masterSpinner = d.ht('#masterSpinner');
		const scrim = document.createElement('div');
		scrim.className = 'scrimBlur';
		scrim.id = 'msScrim';
		
		const ms = document.createElement('div');
		ms.id = 'msSpinner';
		ms.className = 'masterSpinner';
		ms.innerHTML = template.render(masterSpinner, {
			message: 'Authenticating...'
		});
		
		document.body.appendChild(scrim);
		document.body.appendChild(ms);
	}
	
	private static hideMS() {
		const msSpinner = d.get<div>('msSpinner');
		const scrim = d.get<div>('msScrim');
		
		if (msSpinner != null && scrim != null) {
			msSpinner.classList.add('msBounceOut');
			
			msSpinner.addEventListener('animationend', () => {
				scrim.remove();
				msSpinner.remove();
			});
		}
	}
	
	private static getParentHierarchy(e: Event): HTMLElement[] {
		const htmlElements: HTMLElement[] = [];
		
		for (const item of e.composedPath() as HTMLElement[]) {
			if ('classList' in item) { htmlElements.push(item); }
		}
		
		return htmlElements;
	}
	
	private static async passkeyAuth(conditional = false) {
		const resp = await fetch('PasskeySignInRequest', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		
		const resJson = await resp.json();
		if (resJson.error) {
			login.displayError('AUTHPKC1', resJson.error);
			return;
		}
		
		let asseResp: AuthenticationResponseJSON;
		try {
			asseResp = await startAuthentication(resJson.options, conditional);
		} catch (error) {
			let msg = String(error);
			if (error?.name == 'NotAllowedError') {
				msg = 'Authenticator not allowed';
			} else if (error?.name == 'AbortError') {
				return; // if this was user aborted, no need to be loud
			}
			login.displayError('AUTHPKC2', msg);
			throw error;
		}
		
		login.setFormEnabled(false);
		login.showMS();
		
		const verificationResp = await fetch('PasskeySignInResponse', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ ...asseResp, uuid: resJson.uuid })
		});
		
		if (verificationResp.ok) {
			const payload = await verificationResp.json();
			
			if (payload.error || payload.code) { // login error (e.g. AUTH1)
				Cookies.remove('authToken');
				login.displayError(payload.code, payload.error);
				login.setFormEnabled(true);
				login.hideMS();
			} else {
				Cookies.set('authToken', payload.tokenID, {
					expires: 5500,
					sameSite: 'strict',
					secure: true,
					domain: location.host
				});
				
				window.location.reload();
			}
		} else {
			login.displayError('AUTHPKC3', 'Unable to authenticate with Passkey at this time');
			login.setFormEnabled(true);
			login.hideMS();
		}
	}
	
	private static base64Encode(buffer: any) {
		const base64 = window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
		return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
	}
	
	private static base64Decode(base64url: string) {
		const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
		const binStr = window.atob(base64);
		const bin = new Uint8Array(binStr.length);
		
		for (let i = 0; i < binStr.length; i++) {
			bin[i] = binStr.charCodeAt(i);
		}
		
		return bin.buffer;
	}
}

window['login'] = login;
