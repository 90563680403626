/*
	Helper functions for shortcutting DOM manipulation
	
	import d from './DOM';
*/
export type sumber = string | number;
export type input = HTMLInputElement;
export type select = HTMLSelectElement;
export type option = HTMLOptionElement;
export type textarea = HTMLTextAreaElement;
export type button = HTMLButtonElement;
export type div = HTMLDivElement;
export type span = HTMLSpanElement;
export type htmlTemplate = HTMLTemplateElement;
export type anchor = HTMLAnchorElement;
export type img = HTMLImageElement;
export type dialog = HTMLDialogElement;
export type p = HTMLParagraphElement;
export type valueDOM = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
export type DOM = HTMLElement | input | select | textarea | button | div | htmlTemplate | anchor | img | option | dialog | p;
export type ValueDOM = input | select | textarea;

export default abstract class d {
	private static htCache: { [selector: string]: string } = {};
	
	/**
	 * Retrieve the actual DOM object. No # required for ID
	 * @param  {string} id An ID that represents a DOM element that you wish to access
	 */
	public static get<T extends HTMLElement>(id: string) {
		return document.getElementById(id) as T;
	}
	
	/**
	 * QuerySelector over a context
	 * @param  {string} selector A string that represents a CSS Selector Query for the item you wish to access
	 * @param context If present, limits css selector to context
	 */
	public static qs<T extends HTMLElement = DOM>(selector: string, context?: HTMLElement | Document, callbackIfNotNull?: (domObj: T) => void) {
		context = context === undefined || context === null ? document : context;
		
		if (callbackIfNotNull == null) {
			return context.querySelector<T>(selector);
		} else {
			const obj = context.querySelector<T>(selector);
			if (obj != null) { callbackIfNotNull(obj); }
			return null;
		}
	}
	
	/**
	 * QuerySelectorAll over a context
	 * @param  {string} selector A string that represents a CSS Selector Query for the items you wish to access
	 * @param context If present, limits css selector to context
	 */
	public static qsa<T extends HTMLElement = DOM>(selector: string, context?: HTMLElement | Document, callbackIfNotNull?: (domObj: T[]) => void) {
		context = context === undefined || context === null ? document : context;
		
		if (callbackIfNotNull == null) {
			return context.querySelectorAll<T>(selector);
		} else {
			const obj = context.querySelectorAll<T>(selector);
			if (obj != null) { callbackIfNotNull(Array.from(obj)); }
			return null;
		}
	}
	
	/**
	 * QuerySelectorAll over a context - Output to Array by default
	 * @param  {string} selector A string that represents a CSS Selector Query for the items you wish to access
	 * @param context If present, limits css selector to context
	 * @returns An array of HTMLElements that you can immediately use to chain functions like filter, etc
	 */
	public static qa<T extends HTMLElement = DOM>(selector: string, context?: HTMLElement | Document) {
		context = context === undefined || context === null ? document : context;
		const elements = context.querySelectorAll<T>(selector);
		return elements == null ? null : Array.from(elements);
	}
	
	/**
	 * QuerySelector over a context
	 * @param  {string} selector A string[] that represents a CSS Selectors Query for the item you wish to access
	 * @param context If present, limits css selectors to context
	 */
	public static aqs<T = DOM>(selector: string[], context?: HTMLElement | Document) {
		context = context === undefined || context === null ? document : context;
		const domItems: T[] = [];
		
		for (const item of selector) {
			domItems.push(<T>context.querySelector(item));
		}
		
		return domItems;
	}
	
	/**
	 * Array QuerySelectorAll over a context
	 * @param  {string} selector A string[] that represents a CSS Selectors Query for the items you wish to access
	 * @param context If present, limits css selectors to context
	 */
	public static aqsa<T = DOM>(selector: string[], context?: HTMLElement | Document) {
		context = context === undefined || context === null ? document : context;
		const domItems: T[] = [];
		
		for (const item of selector) {
			domItems.push(<T>context.querySelectorAll(item));
		}
		
		return domItems;
	}
	
	/**
	 * Retrieve the innerHTML of an object
	 * @param selector CSS selector
	 * @param context If present, limits css selector to context
	 * @returns A string with the innerHTML or an empty string
	 */
	public static ht<T extends HTMLElement = DOM>(selector: string, context?: HTMLElement | Document) {
		context = context === undefined ? document: context;
		// TODO: review/remove htCache, as it'll always be stale, and has collisions between context/contextless selectors
		if (d.htCache[selector] != null && d.htCache[selector] != '') { return d.htCache[selector]; }
		d.htCache[selector] = context.querySelector<T>(selector)?.innerHTML ?? '';
		return d.htCache[selector];
	}
	
	public static flushHT(selector: string) {
		if (d.htCache[selector] != null && d.htCache[selector] != '') { delete d.htCache[selector]; }
	}
	
	/**
	 * Allows you to build an entire HTML DOM element within a single statement using strongly typed HTMLElement objects
	 * @param domElementTagName The HTMLElement tag you're trying to build
	 * @param className The HTMLElements class value
	 * @param childElements An array of child HTMLElements to add to the this tag - allows multi-dimension construction with the one statement
	 * @param attributes The specific attributes to apply to the master element
	 * @param innerHTML Any string content to apply to the master element
	 * @returns A newly constructed DOM element with infinite number of children
	 */
	public static cr(domElementTagName: string, className?: string, childElements?: HTMLElement[], attributes?: any, innerHTML?: string) {
		const element = document.createElement(domElementTagName);
		
		if (className != null) { element.className = className; }
		if (attributes != null) { Object.keys(attributes).forEach(k => element.setAttribute(k, attributes[k])); }
		if (childElements != null) { childElements.forEach(c => element.appendChild(c)); }
		if (innerHTML != null) { element.innerHTML = innerHTML; }
		return element;
	}
	
	/**
	 * Shortcode to create a document fragment for HTML templating
	 * @returns A document fragment you can start populating
	 */
	public static frag() {
		return document.createDocumentFragment();
	}
	
	/**
	 * Add classes to selectors
	 * @param selector CSS selector
	 * @param classNames An array of classes you wish to add to this selector of element(s)
	 */
	public static cadd(selector: string, classNames: string[]) {
		d.qsa<DOM>(selector).forEach(i => i.classList.add(classNames.join(',')));
	}
	
	/**
	 * Remove classes to selectors
	 * @param selector CSS selector
	 * @param classNames An array of classes you wish to remove to this selector of element(s)
	 */
	public static cremove(selector: string, classNames: string[]) {
		d.qsa<DOM>(selector).forEach(i => i.classList.remove(classNames.join(',')));
	}
	
	/**
	 * Even shorter (document|context).addEventListener() handler. Triggers infinitely
	 * @param eventNames Any valid DOMEvent name (comma separated list accepted)
	 * @param eventHandler A callback you provide that will be passed in the Event object once called
	 * @param context If provided, uses the HTMLElement as the context, otherwise will use document as the context
	 * @param once Boolean - if true, will change this into a SINGLE trigger event and will automatically clean up after itself
	 */
	public static e(eventNames: string, eventHandler: EventListenerOrEventListenerObject, context?: Element | Element[] | HTMLElement | HTMLElement[] | NodeList | Document | Window, once = false) {
		const options: AddEventListenerOptions = {};
		
		if (once == true) {
			options.once = true;
		}
		
		const eventNameList = eventNames.replace(/\s/g, '').split(',');
		
		for (const ename of eventNameList) {
			if (context === undefined) {
				document.removeEventListener(ename, eventHandler, options);
				document.addEventListener(ename, eventHandler, options);
			} else {
				if (context instanceof NodeList || Array.isArray(context)) {
					context.forEach(b => {
						b.removeEventListener(ename, eventHandler, options);
						b.addEventListener(ename, eventHandler, options);
					});
				} else {
					context.removeEventListener(ename, eventHandler, options);
					context.addEventListener(ename, eventHandler, options);
				}
			}
		}
	}
	
	/**
	 * Even shorter (document|context).addEventListener() handler. Triggers once only
	 * @param eventName Any valid DOMEvent name
	 * @param eventHandler A callback you provide that will be passed in the Event object once called
	 * @param context If provided, uses the HTMLElement as the context, otherwise will use document as the context
	 */
	public static e1(eventName: string, eventHandler: (e: any) => void, context?: any) {
		d.e(eventName, eventHandler, context, true);
	}
	
	public static closest<T extends HTMLElement = DOM>(sourceElement: Element | HTMLElement | EventTarget, selector: string, callbackIfNotNull?: (domObj: T) => void) {
		if (callbackIfNotNull == null) {
			return <T>(<HTMLElement>sourceElement).closest(selector);
		} else {
			const obj = <T>(<HTMLElement>sourceElement).closest(selector);
			if (obj != null) { callbackIfNotNull(obj); }
			return null;
		}
	}
	
	public static action<T extends HTMLElement = DOM>(sourceElement: Element | HTMLElement | EventTarget, selector: string, callbackIfNotNull?: (action: string) => void) {
		if (callbackIfNotNull == null) {
			return (<T>(<HTMLElement>sourceElement).closest(selector)).dataset.action;
		} else {
			const obj = <T>(<HTMLElement>sourceElement).closest(selector);
			if (obj != null) { callbackIfNotNull(obj.dataset.action); }
			return null;
		}
	}
	
	/**
	 * A shortcut method to select option items within an HTMLSelectElement whilst being able to populate the select at the same time.
	 * @param selector Either a css selector string or an actual HTMLElement
	 * @param selectedValues An array of values to select (ie. the EXACT STATE) for the multi-select
	 * @param populateAsWell An optional array of "new Option()" HTMLOptionElements to populate the select with at the same time
	 * @returns The fully populated select with each HTMLOptionElement selected as specified
	 */
	public static values(selector: string | HTMLElement, selectedValues: string[], populateAsWell?: HTMLOptionElement[]) {
		let selectObj = null;
		
		if (typeof selector == 'string') {
			selectObj = d.qs<select>(selector);
		} else {
			selectObj = selector;
		}
		
		const isREACHSelect = selectObj.getAttribute('reach-component') != null;
		
		if (populateAsWell != null) {
			if (isREACHSelect) {
				selectObj.kids = populateAsWell;
			} else {
				selectObj.innerHTML = populateAsWell.join(`\n`);
			}
		}
		
		const options = {};
		
		if (isREACHSelect) {
			selectObj.value = selectedValues;
		} else {
			for (let i = 0; i < selectObj.options.length; i++) {
				selectObj.options[i].selected = false;
				options[selectObj.options[i].value] = selectObj.options[i];
			}
			
			for (const item of selectedValues) {
				if (options[item] != null) {
					options[item].selected = true;
				}
			}
		}
		
		return selectObj;
	}
	
	/**
	 * A helper to escape an ID that has a period in it when accessing CSS selectors
	 * @param selector Any CSS selector that has a period in it that needs escaping
	 * @returns An escaped CSS selector
	 */
	public static escape(selector: string) {
		return selector.replace(/\./g, '\\.').replace(/:/g, '');
	}
	
	/**
	 * A helper designed to reduce the amount of casting between object types to make simple DOM lookups easier
	 * @param e The Event object created by javascript
	 * @param selector A string that represents what item you're looking to be returned
	 * @param callbackIfNotNull The callback that will send you the found DOM object in the type you provide (or HTMLElement by default)
	 */
	public static btn<T = HTMLElement>(e: Event, selector: string, callbackIfNotNull: (btn: T) => void) {
		const domObj = <T>(<HTMLElement>e.target).closest(selector);
		if (domObj != null) { callbackIfNotNull(domObj); }
	}
}

window['d'] = d;
